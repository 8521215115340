

















import Vue from 'vue';
export default Vue.extend({
    name: "GradeButton",
    props: {
        value: Object
    },
    computed: {
        styleObject: function() {
            let bgcolor = 'white';
            switch(this.value.grade) {
                case "A":
                    bgcolor = "lightgreen";
                    break;
                case "B":
                    bgcolor = "lightgreen";
                    break;
                case "C":
                    bgcolor = "orange";
                    break;
                case "D":
                    bgcolor = "red";
                    break;
                case "F":
                    bgcolor = "red";
                    break;
            }

            return {
                'background-color': bgcolor,
                color: 'black'
            }
        },
    }
});
