import { IBoard } from '@/models'
import Vue from 'vue'
import Vuex from 'vuex'
import { getData } from '../api/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    boards: new Map<string,IBoard[]>() as Map<string,IBoard[]>,
    loaded: false,
  },
  mutations: {
    setBoards(state, data) {
      state.boards = data;
      state.loaded = true;
    }
  },
  getters: {
    getBoardByKey: (state) => (key: string) => {
        const board = state.boards.get(key);
        if (board?.length === 1)
          return board[0];
    }
  },
  actions: {
    async fetchBoards(store) {
      if (!store.state.loaded) {
        return getData()
          .then(data => {
            store.commit('setBoards', data);
          })
      }
    },
  },
  modules: {
  }
})
