<template lang="pug">
  v-card
    v-toolbar(
        color="primary"
        )
      v-btn(icon to="/") 
        v-icon mdi-arrow-left
      v-toolbar-title {{ board.name }}
    v-container
      v-row
        v-col
            v-icon(color="green") mdi-checkbox-marked-circle 
            | Generally aligns with SOS philosophy
        v-col
            v-icon(color="warning") mdi-alert
            | Mixed or unclear alignment with SOS philosophy
        v-col
            v-icon(color="red") mdi-alert-octagon
            | Diverges from SOS philosophy
        v-row
          v-col
            | In instances where the candidate did not complete the survey, the closest matching answer was used from the candidate's public platform, where possible.
        v-row
          v-col
            | Volunteers made every effort to locate email addresses from municipal election pages to contact all candidates, however, some email addresses could not be found by the time of publishing. SOS Alberta intends to give all candidates an opportunity to respond and is continuing to accept responses; in the interest of full transparency, responses received after publishing on 9 Oct are identified with a 
            v-icon(color="green") mdi-clock-check-outline
        v-row
          v-col
            strong Click on each rating to see the candidate's responses or platform.
    board(:value="board" v-if="loaded")
    v-skeleton-loader(v-else type="table-heading, table, table-thead, table-tbody")
</template>

<script>
  import Vue from 'vue'
  import Board from '../components/Board.vue'
  import { mapState } from 'vuex';

  export default Vue.extend({
    name: 'Home',
    data: () => ({
      board: {},
    }),
    components: {
      Board,
    },
    methods: {
      navigateToBoard() {
        this.board = this.$store.getters.getBoardByKey(this.$route.params.key)
      }
    },
    mounted: function() {
      if (this.loaded) {
        this.navigateToBoard()
      }
    },
    watch: {
      loaded: function(val) {
        if (val) {
          this.navigateToBoard();
        }
      },
      $route: function() {
        console.log('route change')
      }
    },
    computed: {
      ...mapState({
        loaded: state => state.loaded
      })
    }
  })
</script>
