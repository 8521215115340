<template lang="pug">
  v-sheet
    v-card.text-center(:loading="!loaded" )
      v-toolbar(
          color="primary"
          )
        v-toolbar-title SOS Alberta: School Trustee Vote 2021
      v-list(nav)
        v-list-item-group
          v-list-item(v-for="board in boards" :to="{ path: `boards/${board.key}`}" :key="board.key")
            v-list-item-content
              v-list-item-title(v-text="board.name")
    v-card
      v-toolbar(
          color="primary"
          )
        v-toolbar-title Methodology
      v-card-text
        ul.text-left
          li Candidate answers were taken from responses to the SOS Trustee Candidate Survey, from public platforms and public statements. 
          li Where a candidate did not complete the SOS survey, but a comparable answer was found on their public platform, the platform answer was used.
          li Answers were assessed against 
            a(href="https://www.supportourstudents.ca/our-philosophy.html") the SOS Philosophy
          li Answers that adopted strong advocacy positions aligned with SOS philosophy that included tangible actions and demonstrated a knowledge of Alberta's education system were awarded the highest assessment.
          li Where candidates run as part of a 'slate' or group, these are tagged with the slate's name (example: Take Back the CBE). Where these slates' positions are counter to SOS's advocacy for public education, the candidate is rated accordingly.
    v-card
      v-toolbar.mt-5(
          color="primary"
          )
        v-toolbar-title Questions
      v-card-text
        ol.text-left
          li {{ questions.q1Text }}
          li {{ questions.q2Text }}
          li {{ questions.q3Text }}
          li {{ questions.q4Text }}
          li {{ questions.q5Text }}
          li {{ questions.q6Text }}
          li {{ questions.q7Text }}
          li {{ questions.q8Text }}
          li {{ questions.q9Text }}
          li {{ questions.q10Text }}
          
</template>

<script>
  import Vue from 'vue';
  import { mapState } from 'vuex';
  import { getBoards, questions } from '../api/api';


  export default Vue.extend({
    name: 'Home',
    data: () => ({
      boards: [],
      questions
    }),
    mounted: function() {
      this.boards = getBoards()
    },
    computed: {
      ...mapState({
        loaded: state => state.loaded
      })
    }
  })
</script>
