















import Vue from "vue";
import CandidateGradeTable from './CandidateGradeTable.vue';

export default Vue.extend({
  name: "Board",
  props: {
    value: Object
  },
  components: {
    CandidateGradeTable: CandidateGradeTable,
  },
});
