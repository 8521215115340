import csv from 'csvtojson';
import axios from 'axios';
import { from } from 'linq-to-typescript';
import { IGradesCsv, IWard, IBoard } from '@/models';
const trusteeCsvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQex8JvM8HFptLEhCi3rVrzUrDavwqHVEz0toz6XFHinlw8-NIop8hOtEHcN-G40nGTH3p8m540yx-4/pub?output=csv';


export function getBoards() {
    return [
      {
        name: "Calgary Public",
        key: "CBE",
      },
      {
        name: "Calgary Catholic",
        key: "CCSD",
      },
      {
        name: "Edmonton Public",
        key:  "EPSB",
      },
      {
        name: "Edmonton Catholic",
        key: "ECSD",
      },
      {
        name: "Red Deer Public",
        key: "RDPSD"
      },
      {
        name: "Red Deer Catholic",
        key: "RDCRS"
      },
      {
        name: "Lethbridge Public",
        key: "LPSB"
      },
      {
        name: "Holy Spirit Catholic",
        key: "HSCD"
      },
      {
        name: "Grande Prairie School Division",
        key: "GPPSD"
      },
      {
        name: "Grande Prairie Separate School Division",
        key: "GPCSD"
      }
    ];
}

const q1Text = "The majority of Alberta’s school boards have declined to pilot the draft curriculum in 2021. If the government follows through on its stated intention to implement the curriculum in 2022, what position would you take on this, assuming the content is largely unchanged from the current draft?";
const q2Text = "How should school boards balance “parent choice” with the rights of all children to a universally accessible, equitable public education?"
const q3Text = "Do publicly-funded, privately run schools have a place in Alberta’s education system?"
const q4Text = "What limits, if any, should school boards place on school fees, including fees for transportation and extracurricular activities and extra fees for alternative school programming? How should boards address inequities caused by school fees?"
const q5Text = "Should publicly-funded schools be able to deny students admission?"
const q6Text = "Do you support continued public health measures such as masking and cohorting in schools?"
const q7Text = "How should school boards best support children who may have experienced learning losses during the 2020/2021 academic year?"
const q8Text = "How do you see your role as trustee in addressing the rise of mental health concerns in schools?"
const q9Text = "Do you support inclusion initiatives in your board? How can inclusion be improved?"
const q10Text = "Do you approve of standardized testing including provincial achievement and diploma exams, the international PISA test as a means to assess student, school and board performance?"

export const questions = {
    q1Text,
    q2Text,
    q3Text,
    q4Text,
    q5Text,
    q6Text,
    q7Text,
    q8Text,
    q9Text,
    q10Text
}


export async function getData():Promise<Map<string,IBoard[]>>{
    const response = await axios.get(trusteeCsvUrl);
    const csvFileContents = response.data
    const jsonObj = from(await csv().fromString(csvFileContents) as IGradesCsv[])
    return jsonObj.groupBy((x) => x.board).select((x) => {
        return {
          name: x.key,
          wards: x.groupBy((w) => w.ward).select((w) => {
            return {
              name: w.key,
              candidates: w.select((c) => {
                return {
                  ...c,
                  name: c.name,
                  affiliation: c.affiliation.split(',').filter(function(el) {return el.length != 0}),
                  curricGrade: {
                    grade: c.curricGrade,
                    comments: [
                        {
                            q: q1Text,
                            a: c.Q1  
                        }
                    ],
                    url: c.curricCommentSource
                  },
                  publicEducationGrade: {
                      grade: c.publicEducationGrade,
                      comments: [
                          {
                              q: q2Text,
                              a: c.Q2
                          },
                          {
                              q: q3Text,
                              a: c.Q3    
                          },
                          {
                              q: q4Text,
                              a: c.Q4
                          },
                          {
                              q: q5Text,
                              a: c.Q5
                          }
                      ]
                  },
                  covidGrade: {
                      grade: c.covidGrade,
                      comments: [
                          {
                              q: q6Text,
                              a: c.Q6
                          },
                          {
                              q: q7Text,
                              a: c.Q7
                          }
                      ],
                  },
                  mentalHealthGrade: {
                      grade: c.mentalHealthGrade,
                      comments: [
                          {
                            q: q8Text,
                            a: c.Q8
                          }
                      ],
                  },
                  inclusionGrade: {
                      grade: c.inclusionGrade,
                      comments: [
                          {
                            q: q9Text,
                            a: c.Q9
                          }
                      ],
                  },
                  assessmentGrade: {
                      grade: c.assessmentGrade,
                      comments: [
                          {
                            q: q10Text,
                            a: c.Q10
                          }
                      ],
                  },
                } as unknown;
              }).toArray(),
            } as IWard;
          }).toArray(),
        } as IBoard;
      }).toMap(x=>x.name);
}