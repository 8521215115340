






















































import Vue from "vue";
import GradeButton from './GradeButton.vue';

export default Vue.extend({
  name: "CandidateGradeTable",
  components: {
      GradeButton: GradeButton,
  },
  props: {
      value: Object,
  }
});

